import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/app/[locale]/[...slug]/styles.module.css");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/components/HeadlineCardsGrid/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/components/HorizontalPills/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/components/Layout/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/components/Tags/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/components/TogglePanel/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/ad/view.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/carousel/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/carouselPromo/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/carouselRecentlyPlayed/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/category-page/styles.module.css");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/220/s/modular-arena-client/features/iframe-block/index.tsx")